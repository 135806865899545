// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, createContext, useContext } from "react";
import { Form, Button, Col } from "react-bootstrap";
import Url from '../api/defUrl';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import zIndex from '@mui/material/styles/zIndex';
import { useParams } from 'react-router-dom';



 function TemplateDetaljAnkete(props) {
    const { id } = useParams();

    // console.log("id", id)
   
    // console.log("props", props.nastavnik)
const broj_studenata_ukupno = 0
const [anketa, setAnketa] = useState([])


useEffect(() => {
    const fetchNastavnik = async () => {
      try {
        const response = await axios.get(`${Url}/api/anketa/${id}`);
        console.log("response",response)
        setAnketa(response.data);
      } catch (error) {
        console.log('Greška prilikom dohvaćanja podataka o nastavniku:', error);
      }
    };
    fetchNastavnik();
  }, [id]);
  if (!anketa) {
    return <p>Učitavanje podataka...</p>;
  }

    const odgvor1 = []
    // const odgvor2 = []
    const odgvor3 = []
    const odgvor4 = []
    const odgvor21 = []
    // const odgvor22 = []
    const odgvor23 = []
    const odgvor24 = []
    const odgvor31 = []
    // const odgvor32 = []
    const odgvor33 = []
    const odgvor34 = []
    const odgvor41 = []
    const odgvor42 = []
    const odgvor43 = []
    const odgvor44 = []

    const odgvor52 = []
    const odgvor53 = []
    const odgvor54 = []
    const odgvor61 = []
    const odgvor62 = []
    const odgvor63 = []
    const odgvor64 = []
    const odgvor72 = []
    const odgvor73 = []
    const odgvor74 = []
    const odgvor81 = []
    const odgvor82 = []
    const odgvor84 = []
    // const odgvor91 = []
    // const odgvor92 = []
    // const odgvor93 = []
    const odgvor94 = []
    const odgvor95 = []
    const odgvor96 = []
    const odgvor101 = []
    const odgvor102 = []
    const odgvor103 = []
    const odgvor104 = []
    // const odgvor111 = []
    // const odgvor112 = []
    const odgvor113 = []
    const odgvor114 = []
    const odgvor115 = []
    // const odgvor121 = []
    // const odgvor122 = []
    const odgvor123 = []
    const odgvor124 = []
    const odgvor125 = []
    

    let finalniprikazData = []

       // Filtriraj nastavnike koji imaju predmet s odabranim ID-om
  const filtriraniObjekti = finalniprikazData?.filter((objekt) => {
    return objekt?.nastvanik[0] === props?.id;
  });

         
    // console.log("finalniprikazData", finalniprikazData);
    
 
       
        if(anketa?.pitanje1 == 'Redovno'){
          //Nastave je bila redovna
            let pitanje1 = []
            let acc = ""
                acc += anketa?.pitanje1
            pitanje1.push(acc)
            odgvor1.push(pitanje1.length)
            // console.log(pitanje1)
        // }
        // if(anketa?.pitanje1 == 'Ne baš redovno'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor2.push(pitanje2.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje1 == 'Neredovno'){
          //Nastava nije bila redovna
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor3.push(pitanje3.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje1 == 'Nastave nije bilo'){
          //Nastave nije bilo
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor4.push(pitanje4.length)
            // console.log(pitanje1)
        }
//PITANJE 2
        if(anketa?.pitanje2 == 'Nastava je izvođena po rasporedu, bez većih odstupanja'){
          //Nastava je izvođena po rasporedu, bez većih odstupanja
            let pitanje1 = []
            let acc = ""
                acc += anketa?.pitanje1
            pitanje1.push(acc)
            odgvor21.push(pitanje1.length)
            // console.log(pitanje1)
        // }
        // if(anketa?.pitanje2 == 'Nastava je izvođena po rasporedu, sa manjim odstupanjima'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor22.push(pitanje2.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje2 == 'Nastava je izvođena sa velikim odstupanjima od rasporeda'){
          //Nastava je izvođena po rasporedu, sa većim odstupanjima
          //Nastava je izvođena po rasporedu, sa većim odstupanjima
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor23.push(pitanje3.length)
            // console.log(pitanje1)
            //
        }if(anketa?.pitanje2 == 'Nastava nije izvođena po rasporedu'){
          //Nastava nije izvođena po rasporedu
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor24.push(pitanje4.length)
            // console.log(pitanje1)
        }
//PITANJE 3
        if(anketa?.pitanje3 == 'Vrlo zadovoljna/an'){
            let pitanje1 = []
            let acc = ""
                acc += anketa?.pitanje1
            pitanje1.push(acc)
            odgvor31.push(pitanje1.length)
            // console.log(pitanje1)
        // }
        // if(anketa?.pitanje3 == 'Zadovoljana/an'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor32.push(pitanje2.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje3 == 'Djelomično zadovoljna/an'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor33.push(pitanje3?.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje3 == 'Nisam zadovoljna/an'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor34.push(pitanje4?.length)
            // console.log(pitanje1)
        }
        //PITANJE 4
        if(anketa?.pitanje4 == 'Vrlo zadovoljna/an'){
            let pitanje1 = []
            let acc = ""
                acc += anketa?.pitanje1
            pitanje1.push(acc)
            odgvor41.push(pitanje1.length)
            // console.log(pitanje1)
        // }if(anketa?.pitanje4?.pitanje == 'Zadovoljana/an'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor42.push(pitanje2.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje4 == 'Djelomično zadovoljna/an'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor43.push(pitanje3.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje4 == 'Nisam zadovoljna/an'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor44.push(pitanje4.length)
            // console.log(pitanje1)
        // }
        //  //PITANJE 5
        //  if(anketa?.pitanje5?.pitanje == 'U potpunosti'){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor51.push(pitanje1?.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje5 == 'Uglavnom'){
            let pitanje2 = []
            let acc = ""
                acc += anketa?.pitanje2
            pitanje2.push(acc)
            odgvor52.push(pitanje2.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje5 == 'Ponekad'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor53.push(pitanje3.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje5 == 'Nikada'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor54.push(pitanje4.length)
            // console.log(pitanje1)
        // }
        //  //PITANJE 6
        //  if(anketa?.pitanje6?.pitanje == 'U potpunosti'){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor61.push(pitanje1.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje6 == 'Uglavnom'){
            let pitanje2 = []
            let acc = ""
                acc += anketa?.pitanje2
            pitanje2.push(acc)
            odgvor62.push(pitanje2.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje6 == 'Ponekad'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor63.push(pitanje3.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje6 == 'Nikada'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor64.push(pitanje4.length)
            // console.log(pitanje1)
        // }
        //          //PITANJE 7
        // if(anketa?.pitanje7 == 'U potpunosti'){
        //             let pitanje1 = []
        //             let acc = ""
        //                 acc += anketa?.pitanje1
        //             pitanje1.push(acc)
        //             odgvor71.push(pitanje1.length)
        //             // console.log(pitanje1)
        }if(anketa?.pitanje7 == 'Uglavnom'){
                    let pitanje2 = []
                    let acc = ""
                        acc += anketa?.pitanje2
                    pitanje2.push(acc)
                    odgvor72.push(pitanje2.length)
                    // console.log(pitanje1)
        }if(anketa?.pitanje7 == 'Ponekad'){
        let pitanje3 = []
        let acc = ""
            acc += anketa?.pitanje3
            pitanje3.push(acc)
            odgvor73.push(pitanje3.length)
             // console.log(pitanje1)
        }if(anketa?.pitanje7 == 'Nikada'){
        let pitanje4 = []
        let acc = ""
            acc += anketa?.pitanje4
            pitanje4.push(acc)
            odgvor74.push(pitanje4.length)
            // console.log(pitanje1)
        }
        if(anketa?.pitanje8 == 'Potpuno su i stalno dostupni'){
            let pitanje1 = []
            let acc = ""
                acc += anketa?.pitanje1
            pitanje1.push(acc)
            odgvor81.push(pitanje1.length)
            // console.log(pitanje1)
        }if(anketa?.pitanje8 == 'Uglavnom su na raspolaganju (uz manje nedostatke)'){
            let pitanje2 = []
            let acc = ""
                acc += anketa?.pitanje2
            pitanje2.push(acc)
            odgvor82.push(pitanje2.length)
        }if(anketa?.pitanje8 == 'Nisu postojali i ne znam koji su'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
                pitanje4.push(acc)
                odgvor84.push(pitanje4.length)
                // console.log(pitanje1)
        }
       
        // if(anketa?.pitanje9 == 'U dobroj mjeri opterećenja i obima'){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor91.push(pitanje1.length)
        //     // console.log(pitanje1)
        // }if(anketa?.pitanje9 == 'Mogao bi biti manje obiman)'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor92.push(pitanje2.length)
        //     // console.log(pitanje1)
        // }if(anketa?.pitanje9 == 'Mogao bi biti više obiman'){
        //     let pitanje3 = []
        //     let acc = ""
        //         acc += anketa?.pitanje3
        //         pitanje3.push(acc)
        //         odgvor93.push(pitanje3.length)
        //         // console.log(pitanje1)
        // }if(anketa?.pitanje9 == 'Mogao bi biti kompleksniji'){
        //     let pitanje4 = []
        //     let acc = ""
        //         acc += anketa?.pitanje4
        //         pitanje4.push(acc)
        //         odgvor94.push(pitanje4.length)
               
        // }if(anketa?.pitanje9 == 'Mogao bi biti jednostavniji'){
        //     let pitanje4 = []
        //     let acc = ""
        //         acc += anketa?.pitanje4
        //         pitanje4.push(acc)
        //         odgvor95.push(pitanje4.length)
        // }if(anketa?.pitanje9 == 'Previše je obiman i težak'){
        //     let pitanje4 = []
        //     let acc = ""
        //         acc += anketa?.pitanje4
        //         pitanje4.push(acc)
        //         odgvor96.push(pitanje4.length)
        // }
        // if(anketa?.pitanje10?.option1 == true){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor101.push(pitanje1.length)
        //     // console.log(pitanje1)
        // }if(anketa?.pitanje10?.option2 == true){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor102.push(pitanje2.length)
        //     // console.log(pitanje1)
        // }if(anketa?.pitanje10?.option3 == true){
        //     let pitanje3 = []
        //     let acc = ""
        //         acc += anketa?.pitanje3
        //         pitanje3.push(acc)
        //         odgvor103.push(pitanje3.length)
        //         // console.log(pitanje1)
        // }if(anketa?.pitanje10?.option4 == true){
        //     let pitanje4 = []
        //     let acc = ""
        //         acc += anketa?.pitanje4
        //         pitanje4.push(acc)
        //         odgvor104.push(pitanje4.length)
        //         // console.log(pitanje1)
        // }

        // if(anketa?.pitanje11 == 'U potpunosti'){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor111.push(pitanje1.length)
        //     // console.log(pitanje1)
        // // }if(anketa?.pitanje11 == 'U većini slučajeva'){
        // //     let pitanje2 = []
        // //     let acc = ""
        // //         acc += anketa?.pitanje2
        // //     pitanje2.push(acc)
        // //     odgvor112.push(pitanje2.length)
        // //     // console.log(pitanje1)
        // }
        if(anketa?.pitanje9 == 'Uglavnom'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
                pitanje3.push(acc)
                odgvor113.push(pitanje3.length)
                // console.log(pitanje1)
        }if(anketa?.pitanje9 == 'Ponekad'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
                pitanje4.push(acc)
                odgvor114.push(pitanje4.length)
                // console.log(pitanje1)
        }if(anketa?.pitanje9 == 'Nikada'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
                pitanje4.push(acc)
                odgvor115.push(pitanje4.length)
                // console.log(pitanje1)
        // }
        // if(anketa?.pitanje12?.pitanje == 'U potpunosti'){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += anketa?.pitanje1
        //     pitanje1.push(acc)
        //     odgvor121.push(pitanje1.length)
        //     // console.log(pitanje1)
        // }if(anketa?.pitanje12?.pitanje == 'U većini slučajeva'){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += anketa?.pitanje2
        //     pitanje2.push(acc)
        //     odgvor122.push(pitanje2.length)
        //     // console.log(pitanje1)
        }if(anketa?.pitanje10 == 'Uglavnom'){
            let pitanje3 = []
            let acc = ""
                acc += anketa?.pitanje3
                pitanje3.push(acc)
                odgvor123.push(pitanje3.length)
                // console.log(pitanje1)
        }if(anketa?.pitanje10 == 'Ponekad'){
            let pitanje4 = []
            let acc = ""
                acc += anketa?.pitanje4
                pitanje4.push(acc)
                odgvor124.push(pitanje4.length)
                // console.log(pitanje1)
        }if(anketa?.pitanje10 == 'Nikada'){
            let pitanje5 = []
            let acc = ""
                acc += anketa?.pitanje5
                pitanje5.push(acc)
                odgvor125.push(pitanje5.length)
                // console.log(pitanje1)
        }

        if(anketa?.pitanje12 == 'Uglavnom'){
          let pitanje3 = []
          let acc = ""
              acc += anketa?.pitanje3
              pitanje3.push(acc)
              odgvor94.push(pitanje3.length)
              // console.log(pitanje1)
      }if(anketa?.pitanje12 == 'Ponekad'){
          let pitanje4 = []
          let acc = ""
              acc += anketa?.pitanje4
              pitanje4.push(acc)
              odgvor95.push(pitanje4.length)
              // console.log(pitanje1)
      }if(anketa?.pitanje12 == 'Nikada'){
          let pitanje5 = []
          let acc = ""
              acc += anketa?.pitanje5
              pitanje5.push(acc)
              odgvor96.push(pitanje5.length)
              // console.log(pitanje1)
      }
    
   
    // console.log(typeof(odgvor101.length))
    // console.log(odgvor102.length)
    //     console.log(odgvor103.length)
    //     console.log(odgvor104.length)

const suma_odgovora = odgvor1.length + odgvor3.length + odgvor4.length;
const suma_odgovora2 = odgvor21.length + odgvor23.length + odgvor24.length;
const suma_odgovora3 = odgvor31.length + odgvor33.length + odgvor34.length;
const suma_odgovora4 = odgvor41.length + odgvor43.length + odgvor44.length;
const suma_odgovora5 = odgvor52.length + odgvor53.length + odgvor54.length;
const suma_odgovora6 = odgvor62.length + odgvor63.length + odgvor64.length;
const suma_odgovora7 = odgvor72.length + odgvor73.length + odgvor74.length;
const suma_odgovora8 = odgvor81.length + odgvor82.length + odgvor84.length;
const suma_odgovora9 = odgvor94.length+ odgvor95.length+ odgvor96.length;//11
// const suma_odgovora10 = odgvor101.length + odgvor102.length + odgvor103.length + odgvor104.length;
const suma_odgovora11 = odgvor113.length + odgvor114.length+ odgvor115.length;//9
const suma_odgovora12 = odgvor123.length + odgvor124.length+ odgvor125.length;//10

let suma_procenata = 0
const prosjekOdgovori = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata2 = 0
const prosjekOdgovori2 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata2 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}

let suma_procenata3 = 0
const prosjekOdgovori3 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata3 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata4 = 0
const prosjekOdgovori4 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata4 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata5 = 0
const prosjekOdgovori5 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata5 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata6 = 0
const prosjekOdgovori6 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata6 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata7 = 0
const prosjekOdgovori7 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata7 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata8 = 0
const prosjekOdgovori8 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata8 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata9 = 0
const prosjekOdgovori9 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata9 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata10 = 0
const prosjekOdgovori10 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata10 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata11 = 0
const prosjekOdgovori11 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata11 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata12 = 0
const prosjekOdgovori12 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata12 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}

    const duzinaOdg1= [];
    const duzinaOdg2=[];
    const duzinaOdg3=[];
    const duzinaOdg4=[];


    if(odgvor101.length > 1){
        duzinaOdg1.push(odgvor101.length/2)
    } else{
        duzinaOdg1.push(odgvor101)
    }if(odgvor102.length > 1){
        duzinaOdg2.push(odgvor102.length/2)
    } else{
        duzinaOdg2.push(odgvor102)
    }if(odgvor103.length > 1){
        duzinaOdg3.push(odgvor103.length/2)
    } else{
        duzinaOdg3.push(odgvor103)
    }if(odgvor104.length > 1){
        duzinaOdg4.push(odgvor104.length/2)
    }else{
        duzinaOdg4.push(odgvor104)
    }

  return (
    <>
    <Form className="asuFormular">
     <h3>Predmet: 
        {anketa && anketa?.predmet?.map((e)=>e?.nazivPredmeta)}
        </h3>
   
     <Table responsive="sm">
        <thead>
          <tr>
            <th>1</th>
            <th>Nastava na predmetu je izvođena:</th>
            <th>Broj odgovora</th>
            {/* <th>Procenat %{}</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.1</td>
            <td>Nastave nije bilo</td>
            <td>{odgvor4.length}</td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>Nastava nije bila redovna</td>
            <td>{odgvor3.length}</td>
            {/* <td>Ne baš redovno</td>
            <td>{odgvor2.length}</td> */}
            {/* <td>{prosjekOdgovori(odgvor2.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>1.3</td>
            <td>Nastave je bila redovna</td>
            <td>{odgvor1.length}</td>
            {/* <td>{prosjekOdgovori(odgvor3.length,broj_studenata_ukupno)}</td> */}
          </tr>
          {/* <tr>
            <td>1.4</td>
            <td>Nastave nije bilo</td>
            <td>{odgvor4.length}</td>
          </tr> */}
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora}</td>
            {/* <td>{suma_procenata}%</td> */}
          </tr>
       
        </tbody>
   {/* 2 odgovor */}
        <thead>
          <tr>
            <th>2</th>
            <th>Da li je nastava izvođena u skladu sa rasporedom nastave?:</th>
            {/* <th>Broj odgovora</th>
            <th>Procenat %{}</th> */}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>2.1</td>
            <td>Nastava nije izvođena po rasporedu</td>
            <td>{odgvor24.length}</td>
            {/* <td>{prosjekOdgovori2(odgvor24.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>2.2</td>
            <td>Nastava je izvođena po rasporedu, sa većim odstupanjima</td>
            <td>{odgvor23.length}</td>
            {/* <td>{prosjekOdgovori2(odgvor23.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>2.3</td>
            <td>Nastava je izvođena po rasporedu, bez većih odstupanja</td>
            <td>{odgvor21.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            {/* <td>{prosjekOdgovori2(odgvor21.length,broj_studenata_ukupno)}</td> */}
          </tr>
          {/* <tr>
            <td>2.2</td>
            <td>Nastava je izvođena po rasporedu, sa manjim odstupanjima</td>
            <td>{odgvor22.length}</td>
          </tr> */}
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora2}</td>
            {/* <td>{suma_procenata2}%</td> */}
          </tr>
       
        </tbody>

        {/* 3 odgovor */}
        <thead>
          <tr>
            <th>3</th>
            <th>Kvalitetom izvođenja nastave i uzajamnom povezanošću predavanja i vježbi sam:</th>
            {/* <th>Broj odgovora</th>
            <th>Procenat %{}</th> */}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>3.1</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor34.length}</td>
            {/* <td>{prosjekOdgovori3(odgvor34.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>3.2</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor33.length}</td>
          </tr>
          <tr>
            <td>3.3</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor31.length}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora3}</td>
            {/* <td>{suma_procenata3}%</td> */}
          </tr>
       
        </tbody>
          {/* ODGOVOR 4 */}
        <thead>
          <tr>
            <th>4</th>
            <th>Kvalitetom komunikacije i interakcije između profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice i studenata sam:</th>
          </tr>
        </thead>

        <tbody>
        <tr>
            <td>4.1</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor44.length}</td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor43.length}</td>
            {/* <td>{prosjekOdgovori4(odgvor43.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>4.3</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor41.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            {/* <td>{prosjekOdgovori4(odgvor41.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora4}</td>
            {/* <td>{suma_procenata4}%</td> */}
          </tr>
       
        </tbody>
        {/* ODGVOR 5 */}
        <thead>
          <tr>
            <th>5</th>
            <th>Da li sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom možete ostvariti otvorenu komunikaciju, te iskazati svoj stav (čak i ako se razlikuje od stava nastavnika/saradnika)?</th>
          </tr>
        </thead>

        <tbody>
        <tr>
            <td>5.1</td>
            <td>Nikada</td>
            <td>{odgvor54.length}</td>
            {/* <td>{prosjekOdgovori5(odgvor54.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>5.2</td>
            <td>Uglavnom</td>
            <td>{odgvor52.length}</td>
            {/* <td>{prosjekOdgovori5(odgvor52.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>5.3</td>
            <td>Ponekad</td>
            <td>{odgvor53.length}</td>
            {/* <td>{prosjekOdgovori5(odgvor53.length,broj_studenata_ukupno)}</td> */}
          </tr>
         

          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora5}</td>
            {/* <td>{suma_procenata5}%</td> */}
          </tr>
       
        </tbody>
         {/* ODGVOR 6 */}
         <thead>
          <tr>
            <th>6</th>
            <th> Da li osjećate vlastiti napredak radeći sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom?</th>
          </tr>
        </thead>

        <tbody>
        <tr>
            <td>6.1</td>
            <td>Nikada</td>
            <td>{odgvor64.length}</td>
            {/* <td>{prosjekOdgovori6(odgvor64.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>6.2</td>
            <td>Uglavnom</td>
            <td>{odgvor62.length}</td>
            {/* <td>{prosjekOdgovori6(odgvor62.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>6.3</td>
            <td>Ponekad</td>
            <td>{odgvor63.length}</td>
            {/* <td>{prosjekOdgovori6(odgvor63.length,broj_studenata_ukupno)}</td> */}
          </tr>
    
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora6}</td>
            {/* <td>{suma_procenata6}%</td> */}
          </tr>
       
        </tbody>
        {/* ODGVOR  7*/}
        <thead>
          <tr>
            <th>7</th>
            <th>Da li je profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica dostupan/na za konsultacije koje su utvrđene rasporedom?</th>
          </tr>
        </thead>

        <tbody>
        <tr>
            <td>7.1</td>
            <td>Nikada</td>
            <td>{odgvor74.length}</td>
            {/* <td>{prosjekOdgovori7(odgvor74.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>7.2</td>
            <td>Uglavnom</td>
            <td>{odgvor72.length}</td>
            {/* <td>{prosjekOdgovori7(odgvor72.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>7.3</td>
            <td>Ponekad</td>
            <td>{odgvor73.length}</td>
            {/* <td>{prosjekOdgovori7(odgvor73.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora7}</td>
            {/* <td>{suma_procenata7}%</td> */}
          </tr>
       
        </tbody>
         {/* ODGVOR  8*/}
         <thead>
          <tr>
            <th>8</th>
            <th>Sylabuss predmeta i neophodni udžbenici i sredstva za rad:</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>8.1</td>
            <td>Nisu postojali i ne znam koji su</td>
            <td>{odgvor84.length}</td>
            {/* <td>{prosjekOdgovori8(odgvor84.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>8.2</td>
            <td>Uglavnom su na raspolaganju (uz manje nedostatke)</td>
            <td>{odgvor82.length}</td>
            {/* <td>{prosjekOdgovori8(odgvor82.length,broj_studenata_ukupno)}</td> */}
          </tr>


          <tr>
            <td>8.3</td>
            <td>Potpuno su i stalno dostupni</td>
            <td>{odgvor81.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            {/* <td>{prosjekOdgovori8(odgvor81.length,broj_studenata_ukupno)}</td> */}
          </tr>
          
         
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora8}</td>
            {/* <td>{suma_procenata8}%</td> */}
          </tr>
       
        </tbody>
      
        {/* ODGVOR  9*/}
        <thead>
          <tr>
            <th>9</th>
            <th>Da li su vam bili jasno predstavljeni vaši zadaci i obaveze u procesu nastave?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>9.1</td>
            <td>Nikada</td>
            <td>{odgvor115.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor115.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>9.2</td>
            <td>Uglavnom</td>
            <td>{odgvor113.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor113.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>9.3</td>
            <td>Ponekad</td>
            <td>{odgvor114.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor114.length,broj_studenata_ukupno)}</td> */}
          </tr>
          
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora11}</td>
            {/* <td>{suma_procenata11}%</td> */}
          </tr>
       
        </tbody>
         {/* ODGVOR  10*/}
         <thead>
          <tr>
            <th>10</th>
            <th> Da li razumijete povratne informacije koje dobivate od profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice, a odnose se na vaš rad</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>10.1</td>
            <td>Nikada</td>
            <td>{odgvor125.length}</td>
            {/* <td>{prosjekOdgovori12(odgvor125.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>10.2</td>
            <td>Uglavnom</td>
            <td>{odgvor123.length}</td>
            {/* <td>{prosjekOdgovori12(odgvor123.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>10.2</td>
            <td>Ponekad</td>
            <td>{odgvor124.length}</td>
            {/* <td>{prosjekOdgovori12(odgvor124.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora12}</td>
            {/* <td>{suma_procenata12}%</td> */}
          </tr>
       
        </tbody>
        {/* ODGVOR  11*/}
        <thead>
          <tr>
            <th>11</th>
            <th>Da li vam je jasan i transparentan kriterij na osnovu kojeg profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica procjenjuju rad studenata?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>11.1</td>
            <td>Nikada</td>
            <td>{odgvor96.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor115.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>11.2</td>
            <td>Uglavnom</td>
            <td>{odgvor94.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor113.length,broj_studenata_ukupno)}</td> */}
          </tr>
          <tr>
            <td>11.3</td>
            <td>Ponekad</td>
            <td>{odgvor95.length}</td>
            {/* <td>{prosjekOdgovori11(odgvor114.length,broj_studenata_ukupno)}</td> */}
          </tr>
          
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora9}</td>
            {/* <td>{suma_procenata11}%</td> */}
          </tr>
       
        </tbody>
         {/* ODGVOR  12*/}
         <thead>
          <tr>
            <th>12</th>
            <th>Molimo vas da na kraju ostavite dodatni komentar ili sugestiju:(npr. prijedlog unaprijedjenja izvodjenja nastave)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td><p>{anketa?.pitanje13}</p></td>
          </tr>
          
       
        </tbody>
      </Table>

  <br/>
    <br/>
  </Form>
    </>
  );
}

export default TemplateDetaljAnkete;